define("web/controllers/user-options", ["exports", "ember", "web/config/environment"], function (exports, _ember, _webConfigEnvironment) {
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Controller.extend({
    application: _ember["default"].inject.controller(),
    cookies: _ember["default"].inject.service("cookies"),
    isGlobalAdmin: computed(function () {
      var cookies = this.get("cookies").read();
      var cookieAvailable = cookies[_webConfigEnvironment["default"].coreCookieKey];

      if (cookieAvailable) {
        var cookiePayload = window.jwt_decode(cookieAvailable).payload;

        if (cookiePayload.gk || cookiePayload.g[0].r === "GLOBAL_ADMIN") {
          return true;
        }
      }

      return false;
    }),
    actions: {
      saveUserOptions: function saveUserOptions() {
        var _this = this;

        this.get("application").send("showLoader");

        if (this.get("model.phLotNameSource")) {
          this.set("model.userOptions.lotNameSource", "ph_lot_name");
        } else {
          this.set("model.userOptions.lotNameSource", "external_section_name");
        }

        this.get("model.userOptions").save().then(function () {
          _this.get("application").send("hideLoader");
          _this.flashMessages.success("User Options updated successfully.");
        })["catch"](function (err) {
          _this.get("application").send("hideLoader");
          _this.flashMessages.danger(err.message);
        });
      },

      isGlobalAdmin: function isGlobalAdmin() {},

      updateExternalValidationTypeOption: function updateExternalValidationTypeOption(newVal, checked) {
        if (checked) {
          this.get("model.userOptions.externalValidationTypes").pushObject(newVal);
          return;
        }

        this.get("model.userOptions.externalValidationTypes").removeObject(newVal);
      },

      updatePhysicalReceiptOption: function updatePhysicalReceiptOption(toggleValue, checked, key) {
        var _this2 = this;

        var receiptIsOn = this.get("model.userOptions.physicalReceipt");
        this.toggleProperty("model.userOptions.physicalReceipt");

        if (receiptIsOn) {
          ["printPrepaids", "printPermits", "printExemptions", "printPayments", "receiptReprints"].forEach(function (option) {
            _this2.set("model.userOptions." + option, false);
          });
        }
      },

      updateUserOption: function updateUserOption(toggleValue, checked, key) {
        if (toggleValue === "digitalReceipt" && this.get("model.userOptions.digitalReceipt") === true) {
          this.set("model.userOptions.digitalReceiptPrepaids", false);
          return this.toggleProperty("model.userOptions.digitalReceipt");
        }

        if (typeof toggleValue === "string") {
          this.toggleProperty("model.userOptions." + toggleValue);

          key = toggleValue;
          checked = this.get("model.userOptions." + toggleValue);
        }

        this.set("model.userOptions." + key, checked);
      }
    }
  });
});